import * as React from 'react';
import './login.css';

//import { httpSecurity } from '../../core';
import { withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from "react-notifications";
import logo from '../../assets/images/mp_logo.png';

import * as apiClient from "../../network/apiClient";

const mp_logo: any = require('../../assets/images/mp_logo.png');
// const onboarding_asset_1: any = require('../../images/onboarding_asset_1.png');
// const onboarding_asset_2: any = require('../../images/onboarding_asset_2.png');
let username: string = '';
let password: string = '';

interface IProps {
  history?: any;
}

interface IState {
  username?: string;
  password?: string;
}


export class Login extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }
  componentDidMount(){
    const check = localStorage.getItem("token");
    if(check && check !== 'undefined') {
      this.props.history.push("/home");
      return;
    }
  }
  onSignUpNow = () => {
    this.props.history.push("/user-auth-register");
  }
  handelBtnForgotpassword = (event: any) => {
    this.props.history.push('/forgot-password');
  }


  handleBtnLogin = (event: any) => {

    const { username, password } = this.state;
    if (username == "") {
      NotificationManager.error("Please enter email address", "Login", 1000);
      return;
    }
    if (password == "") {
      NotificationManager.error("Please enter password", "Login", 1000);
      return;
    }
    const turiaUser: any = {
      email: this.state.username,
      password: this.state.password,
    };
    apiClient.login(this.state.username, this.state.password).then(res => {
      if (res.status && res.status == "failed") {
        NotificationManager.error(res.message, "Login", 1000);
        return;
      } else {
        localStorage.setItem("token", res.token);
        localStorage.setItem("user", JSON.stringify(res.user));
        this.props.history.push("/home");
      }
    });
  }
  onLogin = () => {
    this.props.history.push("/home");
  }
  render(): JSX.Element {

    return (
      <div className='login-container fullpage-container'>

        <div className="slide-content">
          <div className="slide-data">
            <img src={logo} className="login-logo" />
            <div className="slide-heading welcome-heading">Welcome to Melaninpeople <br />Admin</div>
          </div>
          <div className="slide-image">
            <div className="auth-block-inner">
              <div className="auth-form-screen">
                <input type="text" className="auth-form-input"
                  onChange={(event) => {
                    this.setState({ username: event.target.value });
                  }}
                  placeholder="Email" />
                <input type="password" className="auth-form-input"
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                  placeholder="Password" />
                <div className="auth-actions">
                  {/* <div className="login-btn login-btn-google">Login with Google</div> */}
                  <div className="login-btn login-btn-email" onClick={(event) => {
                    this.handleBtnLogin(event);
                  }}>Login</div>
                </div>
                {/* <div className="forgot-password-parent">
                    <div className="forgot-password-link" onClick={this.handelBtnForgotpassword}>Forgot password</div>
                  </div> */}
                {/* <div className="we-at-turia-parent">
                    <div className="we-at-heading">We at Turia</div>
                    <div className="we-at-turia-content">Are looking forward to to enhance your way in a <br />meaningful way, if you havent signed up yet, please do </div>
                  </div>
                  <div className="auth-actions signup-now-btn">
                    <div className="login-btn login-btn-email" onClick={this.onSignUpNow}>Sign Up Now</div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

