/* import { httpSecurity } from "./http-security"; */

// headers: {
//   Authorization: `Bearer ${accessToken}`,
// },

export const setHeaders = (headers) => {
  if (!headers) headers = new Headers();
  let accessToken = localStorage.getItem("token");
  if (accessToken) headers.append("Authorization", `Bearer ${accessToken}`);

  headers.append("Content-Type", "application/json");
  return headers;
};
export const post = (url, model = null, headers = null) => {
  headers = setHeaders(headers);
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(model),
    headers,
  }).then((r) => {
    if (r.status == 401) {
      return {
        tokenExpired: true,
      };
    }
    if (r.status == 400) {
      return {
        status: "failed",
        message: "Login or password invalid",
      };
    }
    if (!r.ok) {
      throw Error(r.statusText);
    }
    return r.json();
  });
};

export const get = (url, headers = null) => {
  headers = setHeaders(headers);
  return fetch(url, {
    method: "GET",
    headers,
  }).then((r) => {
    if (r.status == 401) {
      return {
        tokenExpired: true,
      };
    }
    if (!r.ok) {
      throw Error(r.statusText);
    }
    return r.json();
  });
};

export const put = (url, model, headers = null) => {
  headers = setHeaders(headers);
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(model),
    headers,
  }).then((r) => {
    if (r.status == 401) {
      return {
        tokenExpired: true,
      };
    }
    if (!r.ok) {
      throw Error(r.statusText);
    }
    return r.json();
  });
};
// delete is reserved word so del
export const del = (url, headers) => {
  headers = setHeaders(headers);
  return fetch(url, {
    method: "DELETE",
    headers,
  }).then((r) => {
    if (r.status == 401) {
      return {
        tokenExpired: true,
      };
    }
    if (!r.ok) {
      throw Error(r.statusText);
    } else if (r.status == 204) {
      return r.statusText;
    }
    return r.json();
  });
};

// }
// export const http = new Http();
