import * as http from "./http";
//import moment from 'moment';

export const APIUrl = "https://api.melaninpeople.com";
// export const APIUrl = "http://localhost:3001";

export const login = (email, password) => {
  let api = `${APIUrl}/users/admin_login`;
  let data = {
    email: email,
    password: password,
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const dashboard = () => {
  let api = `${APIUrl}/users/dashboard`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const registeredusers = (search = "", skip = 0, limit = 20) => {
  let api = `${APIUrl}/users/registered_users?search=${search}&skip=${skip}&limit=${limit}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const reportedUsers = (search = "", skip = 0, limit = 20) => {
  let api = `${APIUrl}/users/reported_users?search=${search}&skip=${skip}&limit=${limit}&sortBy=name:asc`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const disableUser = (userid) => {
  let api = `${APIUrl}/users/disable_user`;
  let data = {
    id: userid,
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const activateUser = (userid) => {
  let api = `${APIUrl}/users/activate_disabled_user`;
  let data = {
    id: userid,
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const deleteUser = (userid) => {
  let api = `${APIUrl}/users/delete_user`;
  let data = {
    id: userid,
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const innerUploadFile = (field, data) => {
  const formData = new FormData();
  formData.append(field, data.rawFile);
  return fetch(`${APIUrl}uploadFile`, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getDashboardEvents = (accountid, roleid) => {
  let api = `${APIUrl}getDashboardEvents/${accountid}/${roleid}`;

  return http.get(api).then((response) => {
    return response;
  });
};

export const createEventvisitorregtemps = (data) => {
  let api = APIUrl + "eventvisitorregtemps";
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const deleteRecord = (path, id) => {
  let api = APIUrl + path + "/" + id;
  return http.del(api).then((response) => {
    return response;
  });
};

export const downloadCSV = () => {
  let api = `${APIUrl}/users/export_csv`;
  return http.get(api).then((response) => {
    return response;
  });
};
