import * as React from "react";
import "./home.css";
import { withRouter } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import post_stats from "../../assets/images/post_stats.png";
import logoutIcon from "../../assets/images/logout_icon.png";
import * as apiClient from "../../network/apiClient";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Bar, Doughnut } from "react-chartjs-2";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
//post_stats.png

interface IProps {
  history?: any;
  location?: any;
}

interface IState {
  data: {
    female: any;
    male: any;
    otherUsers: number;
    totalUsers: any;
    registeredUsers: null;
    totalComments: null;
    totalCount: null;
    totalLikes: null;
    totalPosts: null;
    totalshares: null;
    monthlyUsers: null;
  };
  chartData: any;
  genderChart: any;
}

// const data = {
//     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'oct', 'Nov', 'Dec'],
//     datasets: [
//         {
//             label: 'Registered Users (By Month)',
//             backgroundColor: 'rgba(101,199,188,0.5)',
//             borderColor: 'rgba(39,107,101,1)',
//             borderWidth: 1,
//             hoverBackgroundColor: 'rgba(101,199,188,0.4)',
//             hoverBorderColor: 'rgba(39,107,101,1)',
//             data: [65, 59, 90, 60, 56, 55, 40, 10,15,18,2,1]
//         }
//     ]
// };

const ddata = {
  labels: ["Men", "Women", "Others"],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ["#6251b9", "#36A2EB", "#65c7bc"],
      hoverBackgroundColor: ["#6251b9", "#36A2EB", "#65c7bc"],
    },
  ],
};

export class Home extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: {
        female: 0,
        male: 0,
        otherUsers: 0,
        totalUsers: 0,
        registeredUsers: null,
        totalComments: null,
        totalCount: null,
        totalLikes: null,
        totalPosts: null,
        totalshares: null,
        monthlyUsers: null,
      },
      chartData: null,
      genderChart: null,
    };
  }

  componentDidMount() {
    const check = localStorage.getItem("token");
    if (!check || check == "undefined") {
      this.props.history.push("/");
      return;
    }
    apiClient.dashboard().then((res) => {
      if (res.tokenExpired) {
        localStorage.clear();
        this.props.history.push("/");
        return;
      }
      const otherusers = res.totalCount - (res.female + res.male);
      this.setState({
        data: {
          female: res.female,
          male: res.male,
          otherUsers: 0,
          totalUsers: res.totalCount,
          registeredUsers: res.registeredUsers,
          totalComments: res.totalComments,
          totalCount: res.totalCount,
          totalLikes: res.totalLikes,
          totalPosts: res.totalPosts,
          totalshares: res.totalshares,
          monthlyUsers: res.monthlyUsers,
        },
        chartData: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Count of Registered Users (By Month)",
              backgroundColor: "rgba(101,199,188,0.5)",
              borderColor: "rgba(39,107,101,1)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(101,199,188,0.4)",
              hoverBorderColor: "rgba(39,107,101,1)",
              data: res.monthlyUsers,
            },
          ],
        },
        genderChart: {
          labels: ["Men", "Women", "Others"],
          datasets: [
            {
              data: [res.male, res.female, otherusers],
              backgroundColor: ["#6251b9", "#36A2EB", "#65c7bc"],
              hoverBackgroundColor: ["#6251b9", "#36A2EB", "#65c7bc"],
            },
          ],
        },
      });
    });
  }

  onLogout = () => {
    localStorage.clear();
    this.props.history.push("/");
    return;
  };

  render(): JSX.Element {
    const myData = this.state.data;
    return (
      <div className="home-fullpage-container">
        <Header onClickLogout={this.onLogout} />
        <div className="body">
          <Sidebar />
          <div className="content">
            <div className="page-header">
              <div className="page-title">Analytics</div>
              <div className="page-sub-title">Site level dashboad stats.</div>
            </div>
            <div className="page-stats">
              <div className="stat-block">
                <div className="stat-inner-parent">
                  <img className="stat-img" src={post_stats} />
                  <div className="stat-value">{myData.totalUsers}</div>
                  <div className="stat-name">Total Users</div>
                </div>
              </div>
              <div className="stat-block">
                <div className="stat-inner-parent">
                  <img className="stat-img" src={post_stats} />
                  <div className="stat-value">{myData.totalPosts}</div>
                  <div className="stat-name">Total Posts</div>
                </div>
                {/* <div className="stat-percentage">
                                    12%
                                </div> */}
              </div>
              <div className="stat-block">
                <div className="stat-inner-parent">
                  <img className="stat-img" src={post_stats} />
                  <div className="stat-value">{myData.totalLikes}</div>
                  <div className="stat-name">Total Likes</div>
                </div>
                {/* <div className="stat-percentage">
                                    12%
                                </div> */}
              </div>
              <div className="stat-block">
                <div className="stat-inner-parent">
                  <img className="stat-img" src={post_stats} />
                  <div className="stat-value">{myData.totalComments}</div>
                  <div className="stat-name">Total Comments</div>
                </div>
                {/* <div className="stat-percentage">
                                    12%
                                </div> */}
              </div>
              <div className="stat-block">
                <div className="stat-inner-parent">
                  <img className="stat-img" src={post_stats} />
                  <div className="stat-value">{myData.totalshares}</div>
                  <div className="stat-name">Total Shares</div>
                </div>
                {/* <div className="stat-percentage">
                                    12%
                                </div> */}
              </div>
            </div>
            <div className="dashboard-charts">
              <div className="bar-chart">
                <div className="chart-title">
                  Registered Users (Last 12 Months)
                </div>
                {this.state.chartData && (
                  <Bar
                    data={this.state.chartData}
                    width={400}
                    height={350}
                    options={{
                      maintainAspectRatio: false,
                    }}
                  />
                )}
              </div>
              <div className="gender-charts">
                <div className="chart-title">Registered Users</div>
                {this.state.genderChart && (
                  <Doughnut data={this.state.genderChart} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
