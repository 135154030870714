import React from 'react'
import logoutIcon from "../assets/images/logout_icon.png";

const Header = props => {
  const { onClickLogout } = props
  return (
    <div className="header">
      Melaninpeople
      <button className="logout-btn" onClick={onClickLogout}>
        <img src={logoutIcon} />
        <span className="logout-text">Logout</span>
      </button>
    </div>)
}

export default Header