import * as React from "react";
import "./members.css";
import user from "../../assets/images/user.png";
import logoutIcon from "../../assets/images/logout_icon.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import * as apiClient from "../../network/apiClient";
import downloadIcon from "../../assets/images/download.png";
import FileSaver from "file-saver";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

interface IProps {
  history?: any;
}

interface IState {
  searchText: string;
  skip: number;
  limit: number;
  users: any[];
  hasMore: boolean;
  isLoading: boolean;
  fileDownload: boolean;
}

export class Members extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      searchText: "",
      skip: 0,
      limit: 20,
      users: [],
      hasMore: false,
      isLoading: false,
      fileDownload: false,
    };
  }
  getUsers = () => {
    if (this.state.isLoading) return;
    const { searchText, skip, limit } = this.state;
    this.setState({
      isLoading: true,
      hasMore: false,
    });
    apiClient
      .registeredusers(searchText, skip, limit)
      .then((res) => {
        if (res.tokenExpired) {
          localStorage.clear();
          this.props.history.push("/");
          return;
        }
        let hasm = false;
        if (res.length == this.state.limit) {
          hasm = true;
        }
        let existing = this.state.users;
        let usersArr = [...existing];
        usersArr = usersArr.concat(res);
        this.setState({
          users: usersArr,
          hasMore: hasm,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          hasMore: false,
          isLoading: false,
        });
      });
  };
  getNextUsers = () => {
    let s = this.state.skip;
    s = s + this.state.limit;
    this.setState({ skip: s }, () => {
      this.getUsers();
    });
  };
  onSearch = () => {
    this.setState({ skip: 0, users: [] }, () => {
      this.getUsers();
    });
  };
  completeSearch = (e) => {
    if (e.keyCode === 13) {
      this.setState({ skip: 0, users: [] }, () => {
        this.getUsers();
      });
    }
  };
  componentDidMount() {
    const check = localStorage.getItem("token");
    if (!check || check == "undefined") {
      this.props.history.push("/");
      return;
    }
    this.getUsers();
  }
  onDisableUser = (userid) => {
    apiClient
      .disableUser(userid)
      .then((res) => {
        if (res.tokenExpired) {
          localStorage.clear();
          this.props.history.push("/");
          return;
        }
        console.log("User disabled..");
        this.getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onActivateUser = (userid) => {
    apiClient
      .activateUser(userid)
      .then((res) => {
        if (res.tokenExpired) {
          localStorage.clear();
          this.props.history.push("/");
          return;
        }
        this.getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onDeleteUser = (userid) => {
    apiClient
      .deleteUser(userid)
      .then((res) => {
        if (res.tokenExpired) {
          localStorage.clear();
          this.props.history.push("/");
          return;
        }
        this.setState({ skip: 0, users: [] }, () => {
          this.getUsers();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onDownloadCSV = () => {
    this.setState({
      fileDownload: true,
    });
    apiClient
      .downloadCSV()
      .then((res) => {
        if (res.success) {
          const csvData = new Blob([res.csvData], {
            type: "text/csv;charset=utf-8;",
          });
          FileSaver.saveAs(csvData, "users.csv");
        } else {
          NotificationManager.error(res.message, "CSV Download", 5000);
        }

        this.setState({
          fileDownload: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onLogout = () => {
    localStorage.clear();
    this.props.history.push("/");
    return;
  };
  render(): JSX.Element {
    const { users, searchText, hasMore, isLoading, fileDownload } = this.state;
    return (
      <div className="home-fullpage-container">
        <Header onClickLogout={this.onLogout} />

        <div className="body">
          <Sidebar />
          <div className="content">
            <div className="page-header">
              <div className="page-title">
                Registered Users
                <button className="csv-download" onClick={this.onDownloadCSV}>
                  <span className="download-span">
                    <img className="download-btn" src={downloadIcon} />
                    Download CSV
                  </span>
                </button>
                {fileDownload && <div className="loader download-loader"></div>}
              </div>
              <div className="page-sub-title">Manage registered users</div>
            </div>
            <div>
              <div className="search-box">
                <input
                  type="text"
                  onKeyUp={(event) => {
                    this.completeSearch(event);
                  }}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  value={searchText}
                  placeholder="Search by name, joining date"
                />
                <div className="search-button"> </div>
              </div>
              <button
                className="filter-button"
                onClick={() => {
                  this.onSearch();
                }}
              >
                {" "}
                Filter{" "}
              </button>
            </div>
            <div className="table-responsive">
              <table className="content-table">
                <thead>
                  <tr>
                    <th>Users</th>
                    <th>Date Of Joining</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="user-details">
                              {item.avatar && (
                                <img src={item.avatar} className="user-image" />
                              )}
                              {!item.avatar && (
                                <div className="no-user-image" />
                              )}
                              <div className="user-name">
                                <span className="user-title">
                                  {" "}
                                  {item.name}{" "}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>{item.createdAt}</td>
                          <td>
                            {!item.disabled && (
                              <div className="user-active">Acitve</div>
                            )}
                            {item.disabled && (
                              <div className="user-inactive">Disabled</div>
                            )}
                          </td>
                          <td>
                            <div className="account-actions">
                              {!item.disabled && (
                                <div
                                  className="action-btn disable-btn"
                                  onClick={() => {
                                    this.onDisableUser(item._id);
                                  }}
                                >
                                  Disable
                                </div>
                              )}
                              {item.disabled && (
                                <div
                                  className="action-btn"
                                  onClick={() => {
                                    this.onActivateUser(item._id);
                                  }}
                                >
                                  Activate
                                </div>
                              )}
                              <div
                                className="action-btn delete-btn"
                                onClick={() => {
                                  this.onDeleteUser(item._id);
                                }}
                              >
                                Delete
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="load-more-container">
              {isLoading && <div className="loader"></div>}
              {hasMore && (
                <div className="load-more" onClick={this.getNextUsers}>
                  Load More
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
