import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import queryString from "query-string";
import { ToastContainer, toast, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Login } from "../pages/login/login";
import { Home } from "../pages/home/home";
import { Members } from "../pages/members/members";
import { Report } from "../pages/report/report";

// const bg: any = require("../../images/bg.png");
// const tpButton: any = require("../../images/taskpipeline_button.png");

const history: any = createBrowserHistory();

interface IProps {
    history?: any;
    match?: any;
    location?: any;
}


interface IState {

}
export class AppContainer extends React.Component<IProps, IState> {
    socket = null;
    constructor(props: IProps) {
        super(props);
        // console.log(props);
        const params = queryString.parse(this.props.location.search);
        //console.log(params);
        // if (params.invite_code) {
        //     localStorage.setItem("INVITE_CODE", params.invite_code.toString());
        // } else {
        //     localStorage.removeItem("INVITE_CODE");
        // }
        this.state = {

        };
    }


    componentWillReceiveProps(nextProps: IProps): void {

    }

    componentDidMount(): void {

    }

    render(): JSX.Element {

        return (
            <div className="parentContainer">
                <div className="page">
                    <Switch>
                        <Route path='/home' component={Home}  {...this.props} />
                        <Route path='/members' component={Members}  {...this.props} />
                        <Route exact path='/report' component={Report} {...this.props} />
                        <Route exact path='/' component={Login} {...this.props} />
                    </Switch>
                </div>
                <NotificationContainer />
            </div>
        )
    }
}

