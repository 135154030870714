import React from 'react';
import logo from './logo.svg';
import './App.css';
import { AppContainer } from "./layout/Default";
import { Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

export class App extends React.Component<any, any> {
  history = createBrowserHistory();
  render(): JSX.Element {

    return (
      <div className="App">
        <Router history={this.history}>
          <Route path='/' component={AppContainer} history={this.history} />
        </Router>
      </div>
    )
  }
}

export default App;
