import * as React from "react";
import "./report.css";
import user from "../../assets/images/user.png";
import logoutIcon from "../../assets/images/logout_icon.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import * as apiClient from "../../network/apiClient";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

interface IProps {
  history?: any;
}

interface IState {
  searchText: string;
  skip: number;
  limit: number;
  users: any[];
}

export class Report extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      searchText: "",
      skip: 0,
      limit: 20,
      users: [],
    };
  }
  //reported_users
  getUsers = () => {
    const { searchText, skip, limit } = this.state;
    // this.setState({
    //     users: []
    // });
    apiClient.reportedUsers(searchText, skip, limit).then((res) => {
      if (res.tokenExpired) {
        localStorage.clear();
        this.props.history.push("/");
        return;
      }
      console.log(res);
      this.setState({
        users: res,
      });
    });
  };
  completeSearch = (e) => {
    if (e.keyCode === 13) {
      this.getUsers();
    }
  };
  onDisableUser = (userid) => {
    apiClient
      .disableUser(userid)
      .then((res) => {
        if (res.tokenExpired) {
          localStorage.clear();
          this.props.history.push("/");
          return;
        }
        console.log("User disabled..");
        this.getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onActivateUser = (userid) => {
    apiClient
      .activateUser(userid)
      .then((res) => {
        if (res.tokenExpired) {
          localStorage.clear();
          this.props.history.push("/");
          return;
        }
        this.getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onDeleteUser = (userid) => {
    apiClient
      .deleteUser(userid)
      .then((res) => {
        if (res.tokenExpired) {
          localStorage.clear();
          this.props.history.push("/");
          return;
        }
        this.getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount() {
    const check = localStorage.getItem("token");
    if (!check || check == "undefined") {
      this.props.history.push("/");
      return;
    }
    this.getUsers();
  }
  reportReason = (number) => {
    let reasons = [
      "Spam",
      "Nudity or sexual activity",
      "Hate speech or symbols",
      "Violence or dangerous organizations",
      "Sale of illegal or regulated goods",
      "Bullying or harassment",
      "Intellectual property violation",
      "Suicide, self - injury or eating disorders",
      "Scam or fraud",
      "False Information",
    ];
    if (number >= 0 && number <= 9) {
      return reasons[number];
    } else {
      return "Other Reason";
    }
  };

  onLogout = () => {
    localStorage.clear();
    this.props.history.push("/");
    return;
  };
  render(): JSX.Element {
    const { users, searchText } = this.state;
    return (
      <div className="home-fullpage-container">
        <Header onClickLogout={this.onLogout} />

        <div className="body">
          <Sidebar />

          <div className="content">
            <div className="page-header">
              <div className="page-title">Reported Users</div>
              <div className="page-sub-title">Manage Reported users</div>
            </div>
            <div>
              <div className="search-box">
                <input
                  type="text"
                  onKeyUp={(event) => {
                    this.completeSearch(event);
                  }}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  value={searchText}
                  placeholder="Search by name, joining date"
                />
                <div className="search-button"> </div>
              </div>
              <button
                className="filter-button"
                onClick={() => {
                  this.getUsers();
                }}
              >
                {" "}
                Filter{" "}
              </button>
            </div>
            <div className="table-responsive">
              <table className="content-table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Name</th>
                    <th>Reason</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {item.avatar && (
                              <img src={item.avatar} className="user-image" />
                            )}
                            {!item.avatar && (
                              <img src={user} className="user-image" />
                            )}
                            <div className="user-name">
                              <span className="user-title">
                                {" "}
                                {item.authorName}{" "}
                              </span>
                            </div>
                          </td>
                          <td>{item.authorName}</td>
                          <td>{this.reportReason(item.reason)}</td>
                          <td>
                            <div className="account-actions">
                              {!item.disabled && (
                                <div
                                  className="action-btn disable-btn"
                                  onClick={() => {
                                    this.onDisableUser(item.authorId);
                                  }}
                                >
                                  Disable
                                </div>
                              )}
                              {item.disabled && (
                                <div
                                  className="action-btn"
                                  onClick={() => {
                                    this.onActivateUser(item.authorId);
                                  }}
                                >
                                  Activate
                                </div>
                              )}
                              <div
                                className="action-btn delete-btn"
                                onClick={() => {
                                  this.onDeleteUser(item.authorId);
                                }}
                              >
                                Delete
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
