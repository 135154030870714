import React from "react";
import { Link, NavLink } from "react-router-dom";

const Sidebar = (props) => {
  return (
    <div className="sidebar">
      <div className="sidebar-menu">
        <NavLink className="menuitem" to="/home">
          Dashboard
        </NavLink>
        <NavLink className="menuitem " to="/members">
          Members
        </NavLink>
        <NavLink className="menuitem " to="/report">
          Report
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
